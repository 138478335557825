<template>
  <div>
    <section class="hero is-medium is-secondary">
      <div class="hero-body">
        <div class="container">
          <p class="title is-capitalized">implants as low as $750 uSD</p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container pt-6">
        <div class="columns is-variable is-6 is-align-items-center">
          <div class="column is-half">
            <p class="title">Los Algodones Dental Implant Cost</p>
            <p>When visiting Sani Dental Group, patients save up to 70% on US Cost. We have several options for the different needs of our patients, with implants starting as low as $750.</p>    
          </div>
          <div class="column is-half">
            <img src="../../images/crowns-to-implants.jpg" class="implants-image">
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <hr class="mt-6 is-hidden-mobile">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Implants',
}
</script>

<style lang="sass" scoped>
.hero
  background-position: center top
  background-size: cover
  background-repeat: no-repeat
  background-image: url('~/src/images/implants-los-algodones-sani-dental-group.png')

.implants-image
  display: block
  margin-left: auto
  margin-right: auto
</style>