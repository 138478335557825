<template>
  <section class="section why">
    <div class="container py-6">
      <div class="columns is-centered">
        <div class="column is-three-quarters-desktop">

          <p class="title has-text-centered mb-6">Why Consider Algodones Dental Implants?</p>

          <div class="columns is-multiline is-variable is-8">
            
            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-tooth-purple.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p class="has-text-weight-semibold">Natural Look</p>
                    <p>Not only look like regular teeth, they also work like natural ones.</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-brush.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p class="has-text-weight-semibold">Life-time Solution</p>
                    <p>The better you take care of your new teeth, the longer they’ll last. Brush, floss, and schedule regular checkups.</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-cookie-bite.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p class="has-text-weight-semibold">Improve Comfort</p>
                    <p>Everyday activities, like eating and talking, without any pain or discomfort.</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-teeth.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p class="has-text-weight-semibold">Bone Kept Intact</p>
                    <p>Unlike other alternatives, Implants do not compromise oral health. They help preserve the jawbone.</p>
                  </div>
                </div>
              </article>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Why',
}
</script>

<style lang="sass" scoped>
@import './src/sass/bulma'

.why
  +mobile
    padding-top: 350px

</style>