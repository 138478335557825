<template>
  <section class="section offer">
    <div class="container">
      <div class="columns is-centered mb-6">
        <div class="column is-two-fifths">
          <p class="title has-text-centered is-capitalized mb-6">We Offer a Comprehensive Range of the Finest Brands and Types of Dental Implants</p>
          <h2 class="subtitle has-text-centered">
          Save 70% on US Cost with Dental Implants in Los Algodones</h2>
          <p>Sani Dental Group is recognized as one of the most prestigious clinics in the country, as well as for its excellence in the area of dental implants in Mexico.</p><br>
          <p>Our procedures have helped thousands of patients get their smiles back. We specialize in full-mouth reconstruction, such as all on 4 dental implants in Los Algodones and all on 6 dental implants in Los Algodones.</p><br>
          <p>We offer the best prices without compromising quality. Our team is integrated by a group of 35+ of the best dentist in Los Algodones with years of experience receiving and caring for patients from all over the world.</p>
        </div>
      </div>
      <div class="columns is-align-items-center">
        <div class="column is-one-fifth">
          <img src="../../images/logo-straumann.png">
        </div>
        <div class="column is-one-fifth">
          <img src="../../images/logo-paltop.png">
        </div>
        <div class="column is-one-fifth">
          <img src="../../images/logo-bhi.png">
        </div>
        <div class="column is-one-fifth">
          <img src="../../images/logo-mis.png">
        </div>
        <div class="column is-one-fifth">
          <img src="../../images/logo-nobel-biocare.png">
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <hr>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Offer',
}
</script>

<style lang="sass" scoped>
.column
  img
    display: block
    margin-left: auto
    margin-right: auto

</style>