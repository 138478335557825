<template>
  <section class="section why-sani">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-three-quarters-desktop">

          <p class="title has-text-centered mb-6">Why Sani Dental Group for Algodones Dental Implants?</p>

          <div class="columns is-multiline is-variable is-8">
            
            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-tooth-blue.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>All Specialties in one facility</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-covid.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>The first accredited dental clinic in Los Algodones with Covid-19 Shield</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-experience.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>More than 35 years of experience</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-shuttle.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>Shuttle Service and Accommodation option</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-doctor.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>Trained Staff and Highly Experienced Dental Implant Specialists</p>
                  </div>
                </div>
              </article>
            </div>

            <div class="column is-half">
              <article class="media mb-5">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="../../images/icon-five-years.svg">
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>Up to 5 years warranty including dental implants</p>
                  </div>
                </div>
              </article>
            </div>

          </div><!-- .columns -->

          <div class="columns mt-6 is-variable is-6">
            <div class="column is-hidden-mobile">
              <img src="../../images/pic-01.jpg">
            </div>
            <div class="column">
              <img src="../../images/pic-02.jpg">
            </div>
            <div class="column is-hidden-mobile">
              <img src="../../images/pic-03.jpg">
            </div>
          </div><!-- .columns -->

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhySani'
}
</script>
