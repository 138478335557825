<template>
	<main>
		<cover></cover>
		<why></why>
		<how></how>
		<offer></offer>
		<implants></implants>
		<why-sani></why-sani>
		<rescue></rescue>
	</main>
</template>

<script>
import Cover from './components/Cover.vue'
import Why from './components/Why.vue'
import How from './components/How.vue'
import Offer from './components/Offer.vue'
import Implants from './components/Implants.vue'
import WhySani from './components/WhySani.vue'
import Rescue from './components/Rescue.vue'

export default {
	name: 'App',
	components: {
		Cover,
		Why,
		How,
		Offer,
		Implants,
		WhySani,
		Rescue,
	}
}
</script>
