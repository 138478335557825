import '../sass/app.sass'

// Dependencies
import Vue from 'vue';
window.axios = require('axios');
import Buefy from 'buefy';
import App from './App.vue';

window.axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json' 
};

// Config
Vue.config.productionTip = false;
Vue.use(Buefy);

// Vue Instance
new Vue({
  render: h => h(App),
}).$mount('#app');

