<template>
  <section class="section how">
    <div class="container">
      <div class="columns is-variable is-6">
        <div class="column is-half">
          <div class="content">
            <p class="title">How it works?</p>
            <ul>
              <li>Implants are surgically placed in the jawbone to replace tooth roots.</li>
              <li>They bond with the natural bone, becoming a solid base to support artificial teeth.</li>
              <li>An abutment is placed on the implant to hold the artificial teeth.</li>
            </ul>
            <p class="title">Algodones Dental Implants: How Long Does The Procedure Take?</p>
            <p>Dental implants in Mexico take two trips to complete with an interval of 6 months between trips. On the first trip, you need to stay 2-3 days for the placement of the implant and a temporary crown (one night stay). On the second trip, you need to stay 3-4 days to complete the treatment.</p>
          </div>
        </div>
        <div class="column is-half">
          <img 
            src="../../images/anatomy-of-healthy-teeth-and-tooth-dental-implant.jpg"
            class="how__image"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'How',
}
</script>

<style lang="sass" scoped>
@import './src/sass/bulma'

.how
  background-color: $blue-ligth

  .columns
    +mobile
      display: flex
      flex-direction: column
      .column:nth-of-type(1)
        order: 2
      .column:nth-of-type(2)
        order: 1

  &__image
    display: block
    margin-left: auto
    margin-right: auto
</style>