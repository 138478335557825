<template>
  <section class="section rescue mt-6">
    <div class="container">
      <div class="columns is-variable is-8 is-align-items-center">
        <div class="column">
          <div class="box px-6 py-6">
            <blockquote class="mb-5">I am extremely pleased and grateful, my expectations were beyond surpassed with the attention and results. Best decision when it comes to affordable dental work.</blockquote>
            <p class="has-text-weight-semibold">Anthony Trujillo</p>
            <p class="has-text-grey is-size-7">California, United States</p>
          </div>
        </div>
        <div class="column">
          <figure class="image is-16by9">
            <iframe class="has-ratio" width="100%" height="auto" src="https://www.youtube.com/embed/VrdF7S5uvWs" frameborder="0" allowfullscreen></iframe>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

  name: 'Rescue',

  data () {
    return {

    }
  }
}
</script>

<style lang="sass" scoped>
@import './src/sass/bulma'

.rescue
  background-color: $blue-ligth
  overflow: hidden

  .box
    position: relative

    &:before,
    &:after
      position: absolute
      content: url('~/src/images/quote.svg')
      white-space: nowrap

    &:before
      left: -82px
      top: -20px

    &:after
      right: -82px
      bottom: -20px
      transform: rotate(180deg)
</style>