<template>
	<div class="form">
		<form @submit.prevent="submit">

			<b-field>
        <b-input
          v-model="form.name"
          type="text"
          placeholder="Full name"
          required
        ></b-input>
      </b-field>

      <b-field>
        <b-input
          v-model="form.phone"
          type="tel"
          placeholder="Phone"
          required
        ></b-input>
      </b-field>

      <b-field>
        <b-input
          v-model="form.email_address"
          type="email"
          placeholder="Email"
          required
        ></b-input>
      </b-field>

      <b-field>
        <b-input
          v-model="form.message"
          type="textarea"
          placeholder="I want an estimate on implants "
          required
        ></b-input>
      </b-field>

      <button
        type="submit"
        class="button is-secondary  is-radiusless mt-3"
        :class="{ 'is-loading' : submitStatus === 'PENDING' }"
        :disabled="submitStatus === 'PENDING'"
      >
        Get Started
      </button>
		</form>
	</div>
</template>

<script>
const url = process.env.FORM_ENDPOINT

class FormFields {
  constructor(
    name = '',
    email_address = '',
    phone = '',
    message = '',
    source = ''
    ) {
    this.name = name
    this.email_address = email_address
    this.phone = phone
    this.message = message
    this.source = source
  }
}

export default {
  name: 'IsForm',
  props: {
    idMessage: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
  },
  data () {
    return {
    	submitStatus: null,
      form: new FormFields(),
    }
  },
  methods: {
    submit () {
      this.form.source = this.source
      console.log(this.form)
    	this.submitStatus = 'PENDING'
    	axios.post(url, this.form)
    		.then(res => {
    			this.submitStatus = 'OK'
	        this.$buefy.dialog.alert({
	          type: 'is-success',
	          hasIcon: true,
	          icon: 'check',
	          iconPack: 'fas',
	          title: 'Thank you',
	          message: `<span class="is-size-6" id="${this.idMessage}">Our team of patient coordinators will receive a copy of your estimate. If you want to talk with one of our coordinators right away, contact us at (928)-257-1307</span>`,
	          duration: 5000
	        })
    		})
    		.catch(err => {
    			this.$buefy.toast.open({
	          message: err.response.data.message,
	          type: 'is-danger',
	          position: 'is-bottom',
	          duration: 5000
	        })
    		})
    		.finally(() => this.form = new FormFields())
    }
  }
}
</script>

<style lang="css" scoped>
</style>