<template>
  <section class="section cover pt-5">
    <!-- <div class="cover__overlay"></div> -->
    <div class="container">
      <div class="columns">
        <div class="column">
          <img src="../../images/logo-sdg.png" class="cover__logo">
        </div>
      </div>
      <div class="columns is-variable is-8-desktop is-align-items-center ">
        <div class="column">
          <h1 class="title has-text-white has-text-centered-mobile mb-6">
            Everyone Deserves <br> A Beautiful And Functional Smile
          </h1>
          <h2 class="subtitle has-text-white has-text-centered-mobile">
          Dental Implants in Los Algodones,<br> Your Best Accessible Dental Solution</h2>
        </div>
        <div class="column is-two-fifths">

          <div class="cover__box box is-radiusless px-6 py-6">
            <h2 class="title is-4 is-size-5-mobile">
              Request your <br> treatment estimate
            </h2>
            <IsForm 
              id-message="formDentalImplants"
              source="Dental Implants"
            />
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IsForm from './IsForm.vue'

export default {
  name: 'Cover',
  components: {
    IsForm
  }
}
</script>

<style lang="sass" scoped>
@import './src/sass/bulma'

.cover
  background-position: 50% 0
  background-size: cover
  position: relative
  background-image: url('~/src/images/sdg-landing-cover.jpg')
  background-color: rgba($primary, 0.8)
  background-blend-mode: multiply

  &__overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba($primary, 0.72)

  &__logo
    max-width: 100px

  &__box
    +mobile
      margin-bottom: -370px
</style>